import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Faqs from "../components/Common/Faqs"
import Hero from "../components/Contact/Hero"
import SEO from "../components/App/SEO"
import ClientFeedback from "../components/Index/ClientFeedback"


const Contact = () => {
    return (
        <Layout>
            <SEO title="Contactează SiteBunker - Provider hosting și domenii România"
                 description="Peste 4500 clienți au ales serviciile SiteBunker. Din 2006 oferim servicii premium de găzduire web, domenii și servere VPS. Suport tehnic 24/7."
            />
            <Navbar/>
            <Hero/>
            <Faqs/>
            <ClientFeedback
                title={"Află motivele pentru care clienții au ales serviciile de "}
                bold={"web hosting SiteBunker"}/>
            <Footer/>
        </Layout>
    )
}

export default Contact
