import React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion"

import '/src/assets/css/Common/faqs.css'
import {Link} from "gatsby"

const Faqs = () => {
    return (
        <div className="ptb-100">
            <div className="container">
                <h1 className="main-color mb-4">Întrebări Frecvente</h1>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Ce este găzduirea web?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Hostingul SiteBunker îți permite să publici site-ul tău pe internet. Pentru a deveni
                                    vizibil site-ul tău va avea nevoie de un serviciu de găzduire web.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Am nevoie de cunoștințe tehnice pentru găzduire web?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Serviciile SiteBunker de găzduire web sunt accesibile tuturor, indiferent dacă te
                                    afli la primul proiect sau ești deja expert. Panoul SiteBunker de
                                    control este intuitiv și te ajută să administrezi facil toate domeniile și găzduirea
                                    web. În plus, echipa suport SiteBunker este disponibilă la telefon
                                    și online pentru a te ajuta, indiferent de provocarea tehnică pe care o întâmpini.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Cât timp este necesar pentru a configura un pachet de găzduire web?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Configurarea găzduirii web este rapidă și ușoară. Este nevoie de 5, până la 10 de
                                    minute pentru configurarea pachetului de gazduire web achiziționat de la SiteBunker.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Am nevoie de un nume de domeniu de la SiteBunker pentru a cumpăra găzduire web?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Nu, poți avea deja un domeniu, pe care îl putem transfera la SiteBunker. De altfel,
                                    dacă dorești poți înregistra un domeniu <a
                                    href="https://sitebunker.ro/client/cart.php?a=add&domain=register&language=romanian&currency=2"
                                    target="_blank">aici</a>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Care este diferența dintre găzduire web Linux și găzduire web Windows ?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Linux și Windows sunt două sisteme de operare diferite. Sistemul de operare pe care
                                    îl utilizezi pe
                                    computerul tău nu ar trebui să determine alegerea ta. De exemplu, dacă computerul
                                    este redat de
                                    Microsoft Windows, găzduirea Linux va funcționa la fel de bine ca și Windows
                                    hosting. Alegerea între
                                    Linux și Windows web hosting depinde de stilul tău de web building. Dacă vrei să
                                    utilizezi PHP, MySQL
                                    sau Perl, împreună cu o serie de programe software, Linux este recomandat pentru
                                    tine. Cu toate acestea,
                                    dacă vrei să incluzi baze de date care pot fi căutate și aplicații specifice
                                    Windows, cum ar fi ASP, atunci
                                    SiteBunker îți recomandă Windows hosting web.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Cum pot deveni reseller hosting SiteBunker?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    La SiteBunker, fiecare reseller hosting beneficiază de avantajele unui parteneriat
                                    de încredere.
                                    Pentru că succesul tău reprezintă şi succesul SiteBunker îți punem la dispoziție o
                                    gamă largă de
                                    servicii complete de hosting cu resurse premium incluse la costuri avantajoase,
                                    despre care poți afla detalii complete <Link to="/gazduire-reseller-ssd">aici</Link>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Cum pot deveni afiliat SiteBunker?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Programul SiteBunker de afiliere se adresează exclusiv pachetelor de găzduire,
                                    inclusiv servere dedicate
                                    și VPS-uri. Oferim 15% comision pentru fiecare plată recurentă pe care o face un
                                    client adus de tine.
                                    Dacă dorești să aplici la programul SiteBunker de afiliere te rugăm să ne
                                    contactezi <Link to="/afiliere">aici</Link>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Cum pot beneficia de IP dedicat?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    La SiteBunker poți beneficia de IP dedicat în doar 4 pași simpli. Verifică acest
                                    tutorial și află cum poți lansa comanda de <a
                                    href="https://sitebunker.ro/client/index.php?rp=/knowledgebase/33/Achizitia-unui-IP-dedicat-in-4-pasi.html"
                                    target="_blank">IP dedicat</a>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Am posibilitatea să închiriez propriul server de hosting?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Acum este simplu să ai propriul server hosting. Descoperă ce beneficii includ
                                    pachetele dedicate pentru server hosting oferite de SiteBunker <Link
                                    to="/servere-dedicate">aici</Link>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default Faqs
