import React, { Component } from "react"
import axios from "axios"
import emailjs from "emailjs-com"
import { Formik, Form, Field, ErrorMessage } from "formik"
import "../../assets/css/ContactForm/contact.css"
import * as Yup from "yup"
import FormConfirmMessages from "../Common/FormConfirmMessages"

class ContactForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      formRequestSend: false,
      responseMsg: false
    }
  }

  sendContact(formData) {
    this.setState({ formRequestSend: false })
    emailjs.send("service_m368v5a", "template_zqbdxqa", formData, "user_QzYlAeR2qOAc7gQDDORKV")
      .then((result) => {
        this.setState({ formRequestSend: true })
        this.setState({ responseMsg: true })
      }, (error) => {
        this.setState({ formRequestSend: true })
        this.setState({ responseMsg: false })
      })
  }

  render() {
    const {formRequestSend, responseMsg} = this.state
    const validate = Yup.object({
      name: Yup.string().required("Câmpul este necesar"),
      email: Yup.string().email("Emailul nu este valid").required("Câmpul este necesar"),
      service: Yup.string().required("Câmpul este necesar"),
      message: Yup.string().min(10, "Minim 10 caractere!").required("Câmpul este necesar")
    })
    return (
      <section className="contact-area pt-100">
        <div className="container">

          <div className="row">

            {/*Contact Information*/}
            <div className="col-12">
              <h1 className="main-color mb-5">Informații</h1>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="contact-info">

                <ul className="list-unstyled mb-5">
                  <li>EXIM HOST SRL</li>
                  <li>CUI: RO40672655</li>
                </ul>

                <ul className="list-unstyled mb-5">
                  <li>Str. Frunzei nr. 109</li>
                  <li>Galați, România</li>
                </ul>

                <ul className="list-unstyled mb-5">
                  <li>IBAN: RO38INGB0000999911797852</li>
                  {/*<li>IBAN EUR: RO29INGB0000999911797917</li>*/}
                  {/*<li>IBAN USD: RO07INGB0000999911797925</li>*/}
                  <li>TREZORERIE: RO47TREZ3065069XXX018315</li>
                </ul>

                <ul className="list-unstyled mb-5">
                  <li><a href="mailto:contact@sitebunker.ro">
                    contact@sitebunker.ro
                  </a></li>
                  <li>
                    <a href="tel:0377101459">
                      0377 101 459
                    </a>
                  </li>
                </ul>

                <ul className="list-unstyled mb-5">
                  <li>Program telefonic:</li>
                  <li>Luni – Vineri: 9:00 – 17:00</li>
                  <li>exceptând sărbătorile legale</li>
                </ul>
              </div>
            </div>

            {/*Contact Form*/}
            <div className="col-lg-7 ml-auto col-md-12">
              <FormConfirmMessages formRequestSend={formRequestSend} responseMsg={responseMsg} />
              <div className="contact-form ml-0">

                <Formik initialValues={{ name: "", email: "", service: "", message: "" }} validationSchema={validate}
                        onSubmit={(data, { setSubmitting, resetForm }) => {
                          setSubmitting(true)
                          this.sendContact(data)
                          axios.post("https://sitebunker-app.herokuapp.com/contact-forms", data).then(response => {
                          })
                          setSubmitting(false)
                          resetForm()
                        }}>
                  {({ isSubmitting }) => (
                    <Form id="contactForm">
                      <div className="row">
                        <div className="col-12 mb-3">
                          <div className="form-group">
                            <Field name="name" type="text" placeholder="Nume" className="form-control" />
                            <ErrorMessage render={msg => <span className="text-danger">{msg}</span>} name="name" />
                          </div>

                        </div>
                        <div className="col-12 mb-3">
                          <div className="form-group">
                            <Field name="email" type="email" placeholder="Email" className="form-control" />
                            <ErrorMessage render={msg => <span className="text-danger">{msg}</span>} name="email" />
                          </div>
                        </div>
                        <div className="col-12 mb-3">
                          <div className="form-group">
                            <Field name="service" type="text" placeholder="Serviciu" className="form-control" />
                            <ErrorMessage render={msg => <span className="text-danger">{msg}</span>} name="service" />
                          </div>
                        </div>
                        <div className="col-12 mb-3">
                          <Field name="message" placeholder="Ce informații doriți sa aflați?" className="form-control"
                                 rows="5" as="textarea" />
                          <ErrorMessage render={msg => <span className="text-danger">{msg}</span>} name="message" />
                        </div>
                        <div className="col-12 mt-3">
                          <button disabled={isSubmitting} type="submit" className="form-btn">
                            Contactează SiteBunker
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ContactForm
