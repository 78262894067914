import React from "react"
import ContactForm from "./ContactForm"
import heroBg from "/src/assets/images/contact/contact-hero.png"
import ContactSvg from "../../assets/svgComponents/contact.svg"


const Hero = () => {
  return (
    <div>
      <div className="contact-hero" style={{ backgroundImage: `url(${heroBg})` }}>
        <div className="container">
          <div className="page-title-content text-left position-relative">
            <ContactSvg className="contact-svg"/>
            <div className="z-index-pos">
              <h1 className="mb-4 main-color">Contact SITEBUNKER</h1>
              <div className="row">
                <div className="col-md-10 col-12">
                  <p className="mb-5">La SiteBunker suntem la dispoziția ta pentru orice întrebare și oferim soluții indiferent
                    de provocarea tehnică pe care o întâmpini. Contactează-ne.</p>
                </div>
              </div>
              <h1 className="main-color mb-4">Suport Tehnic SiteBunker</h1>
              <div className="row">
                <div className="col-md-10 col-12">
                  <p>Suportul tehnic este oferit de SiteBunker 24/7 prin sistemul de tickete performant. Răspundem prompt și telefonic. Program telefonic L-V, între orele 9.00 - 17.00, exceptând sărbătorile legale.</p>
                  <a href='https://sitebunker.ro/client/submitticket.php' className="form-btn mt-3 d-inline-block">Deschide aici cerere suport/ticket</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  )
}

export default Hero
